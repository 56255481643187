// main code

* {
  outline-color: map-get($colors, black-50) !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}

body {
  font-family: $ff;
  color: map-get($colors, black-50);
  font-size: 1.4rem;
  overflow-x: hidden;
  position: relative;

  // custom scrollbar
  &::-webkit-scrollbar {
    width: 0.8rem;
  }

  &::-webkit-scrollbar-track {
    background-color: map-get($colors, white-50);
  }

  &::-webkit-scrollbar-thumb {
    background-color: map-get($colors, pink-100);
    outline: none;
    border-radius: 0rem;
  }

  // change according site color theme
  ::-moz-selection {
    background-color: map-get($colors, pink-100);
    color: map-get($colors, white);
  }

  ::selection {
    background-color: map-get($colors, pink-100);
    color: map-get($colors, white);
  }

  &.modal-open,
  .modal {
    padding-right: 6px !important;
  }

  &.modal-open {
    nav.navbar,
    .categories-navbar.sticky-nav {
      margin-right: 6px;
    }

    #fixed-cart-button,
    #fixed-whatsapp-button {
      transform: translateX(-6px);
    }
  }
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;

  &.px {
    image-rendering: pixelated;
  }

  &.aa {
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: crisp-edges;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

p,
a,
li,
span,
small,
label,
button,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.01);
}

a {
  color: map-get($colors, pink-50);
  cursor: pointer;
  transition: ease-in 0.3s all;

  &:hover {
    color: map-get($colors, wood-50);
  }
}

p {
  color: map-get($colors, gray-50);
  font-weight: 300;
}

/* Animations */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* Helpers */
.bg-config {
  background-size: cover;
  background-repeat: no-repeat;
}

.title-pink {
  color: map-get($colors, pink-50);
  font-family: $Sriracha;
  font-size: 36px;
  transition: ease-in 0.3s all;

  @media (max-width: 767.98px) {
    font-size: 30px;
  }
}

.title-black {
  color: map-get($colors, black-50);
  font-family: $Sriracha;
  font-size: 30px;
  transition: ease-in 0.3s all;

  @media (max-width: 767.98px) {
    font-size: 28px;
  }
}

.top-page {
  margin-top: 12rem;
  transition: ease-in 0.3s all;
  @media (max-width: 991.98px) {
    margin-top: 7rem;
  }
}

.section-padding {
  padding: 6rem 0;
}

[disabled]:hover {
  cursor: not-allowed;
}

/* Buttons */
button {
  all: unset;
}

.btn {
  font-size: 1.6rem;

  &.btn-theme {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #c49f5c;
    align-self: flex-end;
    color: #fff;
    max-width: 200px;
    height: 50px;
    border-radius: 9px;
    text-decoration: none;
    border: none;

    &:hover {
      background: #9b7b40;
    }
  }

  &.btn-theme-alt {
    $this: &;
    color: #fff;
    align-self: flex-end;
    height: 50px;
    border-radius: 9px;
    text-decoration: none;
    border: none;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    line-height: 50px;
    padding: 0 25px;
    position: relative;

    .__content {
      display: flex;
      align-items: center;
      z-index: 1;
    }

    input[type="radio"] {
      display: none;

      &:checked + label {
        background-color: #fff;
      }

      &:checked {
        & ~ .__bg {
          background-color: map-get($colors, pink-50);
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          border: 1px solid;
          border-radius: 8px;
        }

        & ~ .__content {
          label {
            border-color: map-get($colors, white-50);
          }
        }
      }

      &:not(:checked) {
        & ~ .__bg {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
        }

        & ~ .__content {
          color: #2e2e2e;

          label {
            border-color: map-get($colors, pink-50);
            border-width: 2px;
          }
        }

        &:disabled {
          pointer-events: none;

          & ~ .__bg {
            background-color: #e7e7e7;
            cursor: not-allowed;
            border: none;
          }

          & ~ .__content {
            cursor: not-allowed;
            color: #b5b5b5;

            label {
              cursor: not-allowed;
              border-color: #d9d9d9;
            }
          }
        }
      }
    }

    /**
     * Custom Radio Buttons
     */
    label {
      display: inline-flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      transition: opacity 0.3s;
      border: 6px solid #fff;
      margin-right: 16px;
      margin-bottom: 0;
    }

    &:hover {
      background: #d19e03;
    }

    &[disabled] {
      background: #ececec;
      color: #b5b5b5;
      pointer-events: none;
    }
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -198px;
  visibility: hidden;
}

.bs-stepper {
  .bs-stepper-header {
    .step {
      .step-trigger {
        border-radius: 36px;
        border: 1px solid map-get($colors, pink-100);
        padding: 12px 16px;
        font-size: 1.6rem;

        .bs-stepper-circle {
          background-color: map-get($colors, pink-100);
          color: map-get($colors, pink-200);
          font-family: $Sriracha;
          width: 2.6em;
          height: 2.6em;
          border-radius: 50%;
          font-weight: normal;
          margin-right: 1rem;
          align-items: center;
        }

        .bs-stepper-label {
          font-weight: 300;
          font-family: $Sriracha;
        }

        &:focus {
          .bs-stepper-label {
            color: map-get($colors, pink-50);
          }
        }
      }

      &.active {
        .step-trigger {
          box-shadow: 0 0 0 1px map-get($colors, pink-100);
        }
      }
    }
  }

  .line {
    background-color: map-get($colors, pink-100);
  }
}

@media (max-width: 575.98px) {
  .bs-stepper {
    .bs-stepper-header {
      margin: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      .step {
        .step-trigger {
          display: flex;
          flex-direction: row;
          width: -webkit-fill-available;
        }
        &.active {
          width: 100%;
        }
      }
    }

    .line {
      display: none;
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1250px;
  }
}
