@import "navbar-toggler.scss";

// main code
@mixin header-complex {
  transition: ease-in 0.3s all;

  .navbar {
    position: fixed;
    z-index: 1040;
    left: 0;
    right: 0;
    top: 0;
    font-size: 1.9rem;
    transition: ease-in 0.3s all;
    background-color: transparent;
    box-shadow: 0 3px 6px #ae9b9b16;
    padding: 1rem 0.5rem;

    @media (max-width: 991.98px) {
      background-color: map-get($colors, white-50);
      border-bottom: 2px solid map-get($colors, pink-baby);
      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            justify-content: center;
            .nav-link {
              color: map-get($colors, pink-50) !important;
            }
          }
        }
      }
    }

    &.header-shadow {
      box-shadow: 0 3px 6px #ae9b9b16;
      background-color: map-get($colors, white-50);

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: map-get($colors, pink-50) !important;
            }
            .nav-icon {
              background-color: map-get($colors, pink-50);
              svg {
                color: map-get($colors, white-50);
              }
            }
            .navbar-brand {
              .brand-white-delale {
                display: none;
                transition: ease-in 0.3s all;
              }
              .brand-rose-delale {
                width: 90px;
                display: block;
                transition: ease-in 0.3s all;

                @media (max-width: 991.98px) {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    &.small-nav {
      background-color: map-get($colors, white-50);
      transition: ease-in 0.3s all;

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: map-get($colors, pink-50);
            }
            .navbar-brand {
              .brand-white-delale {
                display: none;
                transition: ease-in 0.3s all;
              }
              .brand-rose-delale {
                width: 80px;
                display: block !important;
                transition: ease-in 0.3s all;
              }
            }

            .nav-icon {
              background-color: map-get($colors, pink-50);
              svg {
                color: map-get($colors, white-50);
              }
            }
          }
        }
      }
    }

    .navbar-brand {
      transition: ease-in 0.3s all;

      .brand-colors-delale {
        display: none;
        @media (max-width: 991.98px) {
          display: block;
          width: 140px;
        }
      }
      .brand-white-delale {
        transition: ease-in 0.3s all;
        width: 120px;
        display: block;
        @media (max-width: 991.98px) {
          display: none;
        }
      }
      .brand-rose-delale {
        transition: ease-in 0.3s all;
        display: none;
      }
    }

    .navbar-collapse {
      @media (max-width: 991.98px) {
        margin: inherit;
      }

      .navbar-nav {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .nav-item {
          display: flex;
          align-items: center;
          .nav-link {
            color: map-get($colors, white-50);
            font-family: $Sriracha;
            transition: ease-in 0.3s all;
            &:hover {
              color: map-get($colors, wood-50);
            }
          }

          /*  &.active {
            position: relative;
            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 50%;
              top: 72px;
              height: 2px;
              width: 100%;
              transform: translateX(-50%);
              background-color: map-get($colors, pink-50 );
            }
          } */

          .nav-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: map-get($colors, white-50);
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              color: map-get($colors, pink-50);
            }
          }

          &.cart-icon {
            position: relative;
            & > div {
              position: absolute;
              top: 40%;
              right: -10px;
              transform: translate(50%, -50%);
              img {
                width: 28px;
              }
              span {
                position: absolute;
                transform: translate(50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
                right: -10%;
                top: 24%;
                width: 20px;
                height: 20px;
                background: #c49f5c;
                color: #fff;
                border-radius: 50%;
                font-size: 13px;
              }
            }
          }

          input {
            color: map-get($colors, pink-100);
          }
        }
      }
    }

    .button-wrapper {
      cursor: pointer;
      .navbar-toggler {
        svg {
          color: map-get($colors, pink-50);
        }
      }
    }
  }
}
