@mixin section-cardapio {
  padding: 10rem 0;
  border-bottom: 1px solid map-get($colors, gray-100 );

  @media (max-width: 575.98px){
    padding: 4rem 0;
  }

  .cardapio-content {
    @media (max-width: 991.98px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }
  }
}