@mixin section-recado {
  background-color: map-get($colors, white-100);
  padding: 3rem 0;

  .recado-content {
    @media (max-width: 1199.98px) {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-input__label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    align-items: center;
    border-radius: 42px;
    font-size: 14px;
    font-weight: 600;
    color: map-get($colors, pink-50);
    font-weight: 100;
    font-size: 14px;
    padding: 10px 12px;
    border: 1px solid map-get($colors, pink-50);
  }

  .file-input__label svg {
    height: 16px;
    color: map-get($colors, pink-50);
  }

  .form-control {
    min-height: 50px;
    padding: 0 2rem;
    color: map-get($colors, wood-50);
    font-family: $Sriracha;
    font-size: 15px;
    border: 1px solid #cecece;
    border-radius: 42px;
    background-color: transparent;
    margin-right: 1rem;
    &:focus,
    &:focus-visible {
      border-color: map-get($colors, pink-100);
      outline: none;
      color: map-get($colors, wood-50);
      box-shadow: none;
    }
  }

  .preview-image {
    width: 100px;
    height: 100px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50px;
      object-fit: cover;
    }

    .btn-outline {
      position: absolute;
      right: 10px;
      padding: 0rem 0.6rem;
    }

    .image-container {
      width: 100px;
      height: 100px;
    }
  }
}
