// main code
@mixin section-hero {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 991.98px){
    height: 57vh;
  }

  @media (max-width: 767.98px){
    height: 40vh;
  }
}
