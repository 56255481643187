@mixin card-product {
  display: flex;
  flex-direction: column;
  background-color: map-get($colors, white-50);
  padding: 18px 18px 14px 18px;
  position: relative;
  border-radius: 6px;
  margin-bottom: 2rem;

  &.ready::before {
    content: url("/img/elements/pronta_entrega.png");
    width: 60px;
    height: 60px;
    position: absolute;
    display: block;
    left: 5px;
    top: 5px;
  }

  .product-image {
    width: 100%;
    height: 200px;
    border-radius: 9px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1rem;
  }

  .product-description {
    position: relative;
    padding: 20px;
    background-color: map-get($colors, pink-300);
    border-radius: 0 0 11px 11px;
    transition: ease-in 0.3s all;

    &:hover {
      background-color: #f88688;
      cursor: pointer;
    }

    h4 {
      color: map-get($colors, white-50);
      font-size: 24px;
      font-family: $Sriracha;
    }

    p {
      margin-bottom: 12px;
      color: map-get($colors, white-50);
      font-family: $Sriracha;
    }

    p.description-text {
      height: 45px;
    }

    h3 {
      color: map-get($colors, white-50);
      font-size: 2.3rem;
      margin-bottom: 20px;
      font-family: $Sriracha;
    }

    button {
      position: absolute;
      transform: translate(-50%, 45%);
      bottom: 0;
      left: 50%;
      width: max-content;
    }
  }
}

@mixin card-loja {
  display: flex;
  flex-direction: column;
  background-color: map-get($colors, pink-300);
  padding: 0 10px 10px 10px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 2rem;
  transition: 0.5s ease-out;
  &:hover {
    border-color: #ff5a9c88;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    .card-button {
      transform: translate(-50%, 50%);
      opacity: 1;
    }
  }

  .loja-image {
    height: 260px;
    background-position: center;
    border-radius: 0 0 10px 10px;
    @media (max-width: 575.98px) {
      height: 32rem;
    }
  }
  .loja-content {
    margin-top: 1rem;
    background-color: map-get($colors, white-50);
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loja-title {
      font-family: $Sriracha;
      font-size: 18px;
      text-align: center;
      color: map-get($colors, pink-50);
    }

    .loja-text {
      font-family: $Sriracha;
      color: map-get($colors, pink-50);
      text-align: center;
      margin-bottom: 0px;
    }
    .loja-phone {
      font-family: $Sriracha;
      color: map-get($colors, pink-50);
      text-align: center;
    }
  }
  .card-button {
    transform: translate(-50%, 125%);
    border-radius: 30px;
    border: 2px solid map-get($colors, pink-300);
    background-color: map-get($colors, white-50);
    color: map-get($colors, pink-50);
    font-family: $Sriracha;
    font-size: 16px;
    padding: 1rem 2rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    width: max-content;

    @media (max-width: 575.98px) {
      opacity: 1;
      transform: translate(-50%, 50%);
    }
  }
}

@mixin card-combo {
  display: flex;
  flex-direction: column;
  border: 1px solid map-get($colors, gray-100);
  background-color: map-get($colors, white-50);
  padding: 10px;
  position: relative;
  border-radius: 10px;
  height: 510px;
  margin-bottom: 2rem;
  transition: 0.5s ease-out;
  &:hover {
    border-color: #ff5a9c88;
    box-shadow: 0 4px 18px 0 rgba(230, 86, 158, 0.25);
    .card-button {
      transform: translate(-50%, 50%);
      opacity: 1;
    }
  }

  .combo-image {
    height: 300px;
    background-position: center;
    border-radius: 10px;
    @media (max-width: 575.98px) {
      height: 200px;
    }
  }

  .combo-content {
    padding: 2rem;
    height: 130px;
    .combo-title {
      font-family: $Sriracha;
      font-size: 18px;
      color: map-get($colors, pink-50);
    }

    .combo-subtitle {
      font-family: $Sriracha;
      margin-bottom: 0px;
    }
  }
  .combo-footer {
    display: flex;
    justify-content: space-between;

    @media (max-width: 575.98px) {
      flex-direction: column-reverse;

      .value {
        margin-bottom: 1rem;
      }
    }

    a {
      text-decoration: none;
    }
    .value {
      display: flex;
      align-items: center;
      p {
        color: map-get($colors, pink-50);
        margin-bottom: 0;
        font-family: $Sriracha;
        font-size: 22px;
      }
    }
  }
}

@mixin card-clientes-depoimentos {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  background-color: map-get($colors, white-50);
  padding: 2rem;
  position: relative;
  border-radius: 10px;
  transition: 0.5s ease-out;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  &:hover {
    border-color: #ff5a9c88;
    box-shadow: 0 4px 18px 0 rgba(230, 86, 158, 0.25);
    .card-button {
      transform: translate(-50%, 50%);
      opacity: 1;
    }
  }

  .card-clientes-header {
    border-bottom: 1px solid map-get($colors, gray-100);
    p {
      font-family: $Sriracha;
      font-size: 18px;
      color: map-get($colors, pink-50);
    }
  }

  .card-clientes-image {
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  .card-clientes-footer {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-family: $Sriracha;
    font-size: 18px;
    color: map-get($colors, pink-50);
    p {
      margin-bottom: 0;
    }
  }
}

@mixin card-swiper-detalhe {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  background-color: map-get($colors, white-50);
  position: relative;
  border-radius: 10px;
  transition: 0.5s ease-out;

  &:hover {
    cursor: pointer;
    .card-swiper-img {
      border-color: #ff5a9c88;
      box-shadow: 0 4px 18px 0 rgba(230, 86, 158, 0.25);
    }
  }

  .card-swiper-img {
    transition: 0.5s ease-out;
    padding: 1rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    background-color: map-get($colors, white-50);
    border-radius: 10px;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  .card-swiper-detalhe-dec {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .card-title {
      color: rgb(36, 36, 36);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .card-value {
      color: rgb(36, 36, 36);
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
}

#discount {
  span {
    border-top: none;
    color: #2b2b2b;
    font-weight: 600;
  }
  input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 0;

    &:focus {
      outline: none;
      border: 1px solid #ff5a9d;
    }
    &::placeholder{
      color: #dad8d8;
    }
  }
  .gap-10 {
    gap: 10px;
  }

  .btn-outline {
    padding: 1rem 4rem;
    border-radius: 6px;
  }
}
