@mixin swiperDetalheProduto {
  padding: 20px 5px;
  height: 200px;
  border: 4px solid white;

  .swiper-pagination-bullet-active {
    background-color: map-get($colors, pink-50 );
  }

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    background-color: map-get($colors, pink-50 );
  }
}
