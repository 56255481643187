// main code
@mixin pagination {
  display: block;
  margin: 3rem auto;

  .pagination {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
      justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    li {
      &.disabled { display: none; }

      &.active {
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: map-get($colors, white);
          box-shadow: 0 0 0 .2rem map-get($colors, primary);
          border-radius: .6rem;
          color: map-get($colors, primary);
          font-size: 1.4rem;
          font-weight: bold;
          min-width: 40px;
          height: 40px;
          padding: 0 .5rem;
        }
      }

      span {
        margin: 0 .5rem;
        color: map-get($colors, primary);
        font-weight: bold;
        transition: $transition;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: map-get($colors, light);
        color: map-get($colors, primary);
        text-decoration: none !important;
        font-weight: bold;
        border-radius: .6rem;
        min-width: 40px;
        height: 40px;
        transition: $transition;
        padding: 0 .5rem;
        margin: 0 .5rem;

        &[rel="prev"],
        &[rel="next"] {
          background-color: map-get($colors, light);
          color: map-get($colors, primary);
          font-size: 1.4rem;
          font-weight: bold;
          line-height: 100%;
        }

        &:hover {
          box-shadow: 0 0 0 .2rem map-get($colors, primary);
        }
      }
    }
  }
}
