// main code
@mixin section-instagram {
  padding: 3rem 0;

  img {
    transition: ease-in 0.3s all;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    }
  }
}
