@mixin swiperHero {
  height: 400px;
  border: 4px solid white;
  margin-top: 120px;
  
  @media (max-width: 991.98px){
    height: 250px;
  }

  @media (max-width: 767.98px){
    height: 167px;
  }

  @media (max-width: 575.98px){
    height: 138px;
    margin-top: 90px;
  }
  
  .swiper-wrapper {
    .swiper-slide {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
      
      a {
        text-decoration: none;
      }

      .slide-title, .slide-subtitulo {
        color: map-get($colors, white-50 );
        font-family: $Sriracha;
      }

      .slide-title {
        font-size: 50px;
        text-align: center;
        @media (max-width: 767.98px){
          font-size: 26px;
        }
      }

      .slide-subtitulo {
        font-size: 30px;
        text-align: center;
        @media (max-width: 767.98px){
          font-size: 16px;
        }
      }
    }
  }

  .swiper-button-prev, .swiper-button-next {
    color: white;
    top: 93%;
    background-color: map-get($colors, pink-50);
    width: 40px;
    height: 40px;

    @media (max-width: 991.98px){
      top: 91%;
    }

    @media (max-width: 575.98px){
      display: none;
    }

    &::after {
      font-size: 16px;
    }
  }

  .swiper-button-prev {
    left: auto;
    right: 50%;
    border-radius: 20px 0 0 20px;
  }
  .swiper-button-next {
    left: 50%;
    right: auto;
    border-radius: 0 20px 20px 0;
  }
}