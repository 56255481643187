@mixin swiperCardapio {
  height: 200px;
  border: 4px solid white;

  .swiper-wrapper {
    .swiper-slide {
      border-radius: 10px;
      background-position: center;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: map-get($colors, pink-50 );
  }

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    background-color: map-get($colors, pink-50 );
  }
}