// main code
@mixin navbar-toggler {
  outline: none;
  border: none;
  height: 40px;
  padding: .25rem 0;

  .menu-bar {
    display: block;
    height: 2px;
    width: 20px;
    margin: 7.5px 0;
    margin-left: auto;
    background: map-get($colors, primary);
    cursor: pointer;
    transition: $transition $time;

    &.bar-1 {
      position: relative;
      top: 0;
      right: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      width: 33px;
      transition: $transition $time;
    }

    &.bar-2 {
      position: relative;
      top: 0;
      right: 0;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      width: 33px;
      transition: $transition $time;
    }

    &.bar-3 {
      position: relative;
      top: 0;
      right: 0;
      opacity: 1;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      width: 33px;
      -webkit-transition: $smooth, 600;
      transition: $smooth, 600;
    }
  }

  &.active {
    height: 40px;

    .menu-bar {
      &.bar-1 {
        top: 1rem;
        right: 0;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
      }

      &.bar-2 {
        top: 0;
        right: 0;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
      }

      &.bar-3 {
        top: 0;
        right: -10rem;
        opacity: 0;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
      }
    }
  }
}
