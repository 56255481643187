* {
  outline-color: #2B2B2B !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  color: #2B2B2B;
  font-size: 1.4rem;
  overflow-x: hidden;
  position: relative;
}
body::-webkit-scrollbar {
  width: 0.8rem;
}
body::-webkit-scrollbar-track {
  background-color: #ffffff;
}
body::-webkit-scrollbar-thumb {
  background-color: #FFDADA;
  outline: none;
  border-radius: 0rem;
}
body ::-moz-selection {
  background-color: #FFDADA;
}
body ::selection {
  background-color: #FFDADA;
}
body.modal-open,
body .modal {
  padding-right: 6px !important;
}
body.modal-open nav.navbar,
body.modal-open .categories-navbar.sticky-nav {
  margin-right: 6px;
}
body.modal-open #fixed-cart-button,
body.modal-open #fixed-whatsapp-button {
  transform: translateX(-6px);
}

img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}
img.px {
  image-rendering: pixelated;
}
img.aa {
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

p,
a,
li,
span,
small,
label,
button,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.01);
}

a {
  color: #FF6D6D;
  cursor: pointer;
  transition: ease-in 0.3s all;
}
a:hover {
  color: #9e8080;
}

p {
  color: #939393;
  font-weight: 300;
}

/* Animations */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* Helpers */
.bg-config {
  background-size: cover;
  background-repeat: no-repeat;
}

.title-pink {
  color: #FF6D6D;
  font-family: "Sriracha", cursive;
  font-size: 36px;
  transition: ease-in 0.3s all;
}
@media (max-width: 767.98px) {
  .title-pink {
    font-size: 30px;
  }
}

.title-black {
  color: #2B2B2B;
  font-family: "Sriracha", cursive;
  font-size: 30px;
  transition: ease-in 0.3s all;
}
@media (max-width: 767.98px) {
  .title-black {
    font-size: 28px;
  }
}

.top-page {
  margin-top: 12rem;
  transition: ease-in 0.3s all;
}
@media (max-width: 991.98px) {
  .top-page {
    margin-top: 7rem;
  }
}

.section-padding {
  padding: 6rem 0;
}

[disabled]:hover {
  cursor: not-allowed;
}

/* Buttons */
button {
  all: unset;
}

.btn {
  font-size: 1.6rem;
}
.btn.btn-theme {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #c49f5c;
  align-self: flex-end;
  color: #fff;
  max-width: 200px;
  height: 50px;
  border-radius: 9px;
  text-decoration: none;
  border: none;
}
.btn.btn-theme:hover {
  background: #9b7b40;
}
.btn.btn-theme-alt {
  color: #fff;
  align-self: flex-end;
  height: 50px;
  border-radius: 9px;
  text-decoration: none;
  border: none;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  line-height: 50px;
  padding: 0 25px;
  position: relative;
  /**
   * Custom Radio Buttons
   */
}
.btn.btn-theme-alt .__content {
  display: flex;
  align-items: center;
  z-index: 1;
}
.btn.btn-theme-alt input[type=radio] {
  display: none;
}
.btn.btn-theme-alt input[type=radio]:checked + label {
  background-color: #fff;
}
.btn.btn-theme-alt input[type=radio]:checked ~ .__bg {
  background-color: #FF6D6D;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid;
  border-radius: 8px;
}
.btn.btn-theme-alt input[type=radio]:checked ~ .__content label {
  border-color: #ffffff;
}
.btn.btn-theme-alt input[type=radio]:not(:checked) ~ .__bg {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.btn.btn-theme-alt input[type=radio]:not(:checked) ~ .__content {
  color: #2e2e2e;
}
.btn.btn-theme-alt input[type=radio]:not(:checked) ~ .__content label {
  border-color: #FF6D6D;
  border-width: 2px;
}
.btn.btn-theme-alt input[type=radio]:not(:checked):disabled {
  pointer-events: none;
}
.btn.btn-theme-alt input[type=radio]:not(:checked):disabled ~ .__bg {
  background-color: #e7e7e7;
  cursor: not-allowed;
  border: none;
}
.btn.btn-theme-alt input[type=radio]:not(:checked):disabled ~ .__content {
  cursor: not-allowed;
  color: #b5b5b5;
}
.btn.btn-theme-alt input[type=radio]:not(:checked):disabled ~ .__content label {
  cursor: not-allowed;
  border-color: #d9d9d9;
}
.btn.btn-theme-alt label {
  display: inline-flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: opacity 0.3s;
  border: 6px solid #fff;
  margin-right: 16px;
  margin-bottom: 0;
}
.btn.btn-theme-alt:hover {
  background: #d19e03;
}
.btn.btn-theme-alt[disabled] {
  background: #ececec;
  color: #b5b5b5;
  pointer-events: none;
}

a.anchor {
  display: block;
  position: relative;
  top: -198px;
  visibility: hidden;
}

.bs-stepper .bs-stepper-header .step .step-trigger {
  border-radius: 36px;
  border: 1px solid #FFDADA;
  padding: 12px 16px;
  font-size: 1.6rem;
}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-circle {
  background-color: #FFDADA;
  color: #FF5A9D;
  font-family: "Sriracha", cursive;
  width: 2.6em;
  height: 2.6em;
  border-radius: 50%;
  font-weight: normal;
  margin-right: 1rem;
  align-items: center;
}
.bs-stepper .bs-stepper-header .step .step-trigger .bs-stepper-label {
  font-weight: 300;
  font-family: "Sriracha", cursive;
}
.bs-stepper .bs-stepper-header .step .step-trigger:focus .bs-stepper-label {
  color: #FF6D6D;
}
.bs-stepper .bs-stepper-header .step.active .step-trigger {
  box-shadow: 0 0 0 1px #FFDADA;
}
.bs-stepper .line {
  background-color: #FFDADA;
}

@media (max-width: 575.98px) {
  .bs-stepper .bs-stepper-header {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .bs-stepper .bs-stepper-header .step .step-trigger {
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
  }
  .bs-stepper .bs-stepper-header .step.active {
    width: 100%;
  }
  .bs-stepper .line {
    display: none;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1250px;
  }
}
.erro-404-wrapper {
  padding-top: 4rem;
  margin-top: 12rem;
  height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 991.98px) {
  .erro-404-wrapper {
    margin-top: 7rem;
  }
}
.erro-404-wrapper img {
  bottom: -90px;
  width: 500px;
}
@media (max-width: 767.98px) {
  .erro-404-wrapper img {
    bottom: -49px;
    width: 270px;
  }
}
@media (max-width: 475.98px) {
  .erro-404-wrapper img {
    display: none;
  }
}
.erro-404-wrapper .content-aviso {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.erro-404-wrapper .content-aviso .title {
  font-size: 100px;
  font-family: "Sriracha", cursive;
  color: #FF6D6D;
  text-align: center;
  margin-bottom: 0;
  line-height: 71px;
}
.erro-404-wrapper .content-aviso .subtitle {
  font-size: 30px;
  color: #FF6D6D;
  font-family: "Sriracha", cursive;
  text-align: center;
}

.quantity {
  display: flex;
}
.quantity input {
  height: 35px;
  width: 40px;
  flex: none;
  text-align: center;
  font-size: 1.8rem;
  border: 1px solid #E8E8E8;
  border-right: none;
  border-left: none;
  padding: 0.375rem 0;
  outline: none;
}
.quantity button {
  border: none;
  padding: 0 8px;
  color: #FF5A9D;
  font-size: 1.2rem;
  outline: none;
}
.quantity button.btn-minus {
  border-right: none;
  border-radius: 6px 0 0 6px;
}
.quantity button.btn-plus {
  border-left: none;
  border-radius: 0 6px 6px 0;
}

.categories-navbar {
  background-position: left top;
  background-repeat: repeat-x;
  background-size: contain;
  height: 107px;
  display: flex;
  align-items: center;
}
.categories-navbar.sticky-nav {
  top: 100px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1039;
}
.categories-navbar ul {
  margin-top: -6px;
  padding: 0;
  list-style: none;
  display: flex;
}
.categories-navbar ul li {
  color: #FFDADA;
  opacity: 0.41;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  margin-right: 60px;
  transition: all 0.2s;
  white-space: nowrap;
}
.categories-navbar ul li:last-child {
  margin-right: 0;
}
.categories-navbar ul li.active {
  opacity: 1;
}
.categories-navbar ul li a:hover {
  color: inherit;
  text-decoration: none;
}

#fixed-whatsapp-button {
  position: fixed;
  right: 10px;
  bottom: 70px;
  z-index: 1040;
}
#fixed-whatsapp-button img {
  width: 60px;
}

#cart {
  padding: 50px 0;
  background-color: #ffffff;
}
#cart .title {
  margin-bottom: 25px;
  color: #FF6D6D;
  font-family: "Sriracha", cursive;
}
#cart table.products th {
  border-top: none;
  color: #2B2B2B;
  font-weight: 600;
}
#cart table.products tbody td {
  padding-top: 1rem;
  padding-bottom: 1.8rem;
}
#cart table.products tbody .product {
  display: flex;
  align-items: center;
}
#cart table.products tbody .product img {
  border-radius: 1px;
  width: 100px;
  height: 100px;
  margin-right: 8px;
  object-fit: cover;
  object-position: center;
}
#cart table.products tbody .price {
  font-family: "Sriracha", cursive;
  font-size: 18px;
}
#cart .form-control[type=number]::-webkit-inner-spin-button,
#cart .form-control[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#cart .schedule-wrapper .schedule-options-title {
  display: flex;
  align-items: center;
}
#cart .schedule-wrapper .schedule-options-title svg {
  color: #FF5A9D;
  font-size: 3rem;
  margin-right: 5px;
}
#cart .schedule-wrapper .schedule {
  padding: 30px 20px;
}
#cart .schedule-wrapper .schedule .owl-nav {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-10px, -115%);
  display: flex;
  align-items: center;
}
#cart .schedule-wrapper .schedule .owl-nav button {
  height: 22px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}
#cart .schedule-wrapper .schedule .owl-nav button:focus {
  outline: none;
  border: 1px solid #C49F5C60;
}
#cart .schedule-wrapper .schedule .owl-nav button svg {
  color: #FF5A9D;
  font-size: 2.2rem;
  margin: 0px 6px;
  transition: all 0.2s;
}
#cart .schedule-wrapper .schedule .card-schedule {
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  transition: border 0.1s;
  cursor: pointer;
  margin-inline: 2px;
  min-height: 40px;
  display: flex;
}
#cart .schedule-wrapper .schedule .card-schedule.active {
  border-color: #FF5A9D;
  background-color: #FF5A9D;
}
#cart .schedule-wrapper .schedule .card-schedule.active label {
  color: #ffffff;
}
#cart .schedule-wrapper .schedule .card-schedule label {
  padding: 10px 0;
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
#cart .schedule-wrapper .schedule .card-schedule label input[type=radio] {
  display: none;
}
#cart .address-data {
  padding: 30px 20px;
}
#cart .address-data .alert-warning {
  background-color: #FFDADA;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 8px 8px;
}
#cart .address-data .alert-warning svg {
  color: #FF5A9D;
}
#cart .address-data .alert-warning p {
  font-size: 18px;
  color: #2B2B2B;
}
#cart .address-data .alert-warning p strong {
  color: #9e8080;
}
#cart .address-data .alert-warning p span {
  color: #FF5A9D;
}
#cart .address-data .alert-warning li {
  color: #FF5A9D;
  font-family: "Sriracha", cursive;
  font-size: 18px;
}
#cart .credit-card {
  padding: 30px 20px;
}
#cart .resume {
  margin-bottom: 18px;
  padding: 40px 20px 20px;
  color: #2B2B2B;
}
#cart .resume h2 {
  margin-bottom: 0;
  align-self: flex-start;
}
#cart .resume > div span {
  font-size: 20px;
  font-family: "Sriracha", cursive;
}

#success {
  background-color: #ffffff;
}
#success .container {
  display: flex;
  justify-content: center;
}
#success .container .box-success {
  background: #FEFEFE;
  box-shadow: 0 3px 6px #82828246;
  width: 680px;
  padding: 50px 0 40px;
  text-align: center;
}
#success .container .box-success > .title {
  color: #8DC640;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
#success .container .box-success > .title img {
  width: 38px;
  margin-left: 3px;
}
#success .container .box-success p {
  max-width: 350px;
  margin: 0 auto 25px;
  padding: 0 10px;
}
#success .container .box-success .bg-menu {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 10px;
}
#success .container .box-success .bg-menu .title {
  color: #ffffff;
}
#success .container .box-success a {
  background-color: #C49F5C;
  width: 230px;
  height: 50px;
  margin: 30px auto 0;
}

#quem-somos {
  color: #2B2B2B;
  margin-top: 11rem;
  padding: 90px 0;
  background-position: center 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
#quem-somos .row > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#quem-somos .row > div > div {
  margin: 0 auto;
  background: #C49F5C;
  max-width: 405px;
  margin: -20px 0 0 -20px;
}
#quem-somos .row > div > div video {
  transform: translate(20px, 20px);
  max-width: 100%;
}
#quem-somos h1 {
  font-size: 2.4rem;
  margin-bottom: 10px;
}
#quem-somos a {
  font-weight: 500;
  color: #C49F5C;
  display: flex;
  align-items: center;
}
#quem-somos a img {
  margin-left: 7px;
  width: 18px;
}
#quem-somos a:hover {
  text-decoration: none;
}
#quem-somos p {
  font-weight: 500;
  font-size: 1.6rem;
}

@media (max-width: 1200px) {
  .categories-navbar .container {
    overflow-x: scroll;
  }
}
@media (max-width: 992px) {
  .categories-navbar .container {
    overflow-x: scroll;
  }
  .categories-navbar .container ul li {
    margin-right: 20px;
  }

  #fixed-cart-button {
    right: 50px;
    width: 280px;
    height: 40px;
    padding: 0 30px;
    font-size: 1.4rem;
  }
  #fixed-cart-button span {
    align-items: center;
  }
  #fixed-cart-button span.price {
    font-size: 1.6rem;
  }
  #fixed-cart-button span img {
    width: 22px;
  }

  #fixed-whatsapp-button {
    bottom: 60px;
  }
  #fixed-whatsapp-button img {
    width: 45px;
  }

  #cart .address-data .address {
    margin-bottom: 18px;
  }
  #cart .credit-card .col-lg-6:first-child {
    margin-bottom: 18px;
  }
  #cart .resume {
    flex-direction: column;
  }
  #cart .resume .title {
    align-self: center;
    margin-bottom: 10px;
  }
  #cart .resume > div {
    margin-bottom: 8px;
  }
  #cart .resume button {
    align-self: center;
    margin-top: 5px;
  }

  #success .container .box-success .title {
    font-size: 20px;
  }
  #success .container .box-success .title img {
    width: 32px;
  }
}
@media (max-width: 576px) {
  #fixed-cart-button {
    right: 10px;
    width: 250px;
    height: 30px;
    padding: 0 20px;
    font-size: 1.2rem;
  }
  #fixed-cart-button span {
    align-items: center;
  }
  #fixed-cart-button span.price {
    font-size: 1.4rem;
  }
  #fixed-cart-button span img {
    width: 18px;
  }

  #cart .table-wrapper {
    padding: 0 10px;
    overflow-x: scroll;
  }
  #cart .table-wrapper table.table .product {
    width: 100%;
  }
  #cart label {
    font-size: 12px;
  }
  #cart .credit-card {
    padding: 40px 10px 20px;
  }
  #cart .schedule {
    padding: 20px 5px;
  }
}
.pristine-error {
  color: red;
}

#discount span {
  border-top: none;
  color: #2b2b2b;
  font-weight: 600;
}
#discount input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0;
}
#discount input:focus {
  outline: none;
  border: 1px solid #ff5a9d;
}
#discount input::placeholder {
  color: #dad8d8;
}
#discount .gap-10 {
  gap: 10px;
}
#discount .btn-outline {
  padding: 1rem 4rem;
  border-radius: 6px;
}

.detalhes-mural-top {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.detalhes-mural-top .title {
  font-size: 28px;
  color: #ffffff;
  font-family: "Sriracha", cursive;
  margin-bottom: 0;
}
.detalhes-mural-top .subtitle {
  font-size: 18px;
  color: #ffffff;
  font-family: "Sriracha", cursive;
}

#section-detalhes {
  margin-top: 4rem;
  border-bottom: 1px solid #D9D9D9;
  background-image: url(/img/bg/bg-cofee-delale.png);
}
#section-detalhes .img-product img {
  object-fit: cover;
  width: 100%;
  height: 400px;
  border-radius: 30px;
}
#section-detalhes .detalhes-info {
  padding-bottom: 20px;
  border-bottom: 1px solid #D9D9D9;
}
#section-detalhes .detalhes-info p {
  margin-bottom: 0px;
}
#section-detalhes .detalhes-info .info-id {
  font-weight: 400;
}
#section-detalhes .detalhes-info .info-title {
  font-size: 30px;
  color: #242424;
  font-weight: 500;
  font-family: "Sriracha", cursive;
}
#section-detalhes .detalhes-info .info-value {
  font-family: "Sriracha", cursive;
  font-size: 20px;
  color: #242424;
}
#section-detalhes .info-desc {
  padding: 20px 0;
  border-bottom: 1px solid #D9D9D9;
}
#section-detalhes .btn-duvida {
  padding: 2rem;
  background-color: white;
  border: 1px solid #FB999A;
  text-decoration: none;
  width: 100%;
  text-align: center;
}
#section-detalhes .btn-adicionar {
  width: 100%;
  background: linear-gradient(-45deg, #FF6D6D, #FB999A, #ff82b4, #FF6D6D);
  background-size: 800% 400%;
  padding: 2rem;
  text-decoration: none;
  display: inline-block;
  border: none;
  text-align: center;
  font-size: 17px;
  font-family: "Sriracha", cursive;
  font-weight: 300;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
}
#section-detalhes .btn-adicionar:hover {
  animation: gradient 3s infinite;
  transform: scale(1.05);
}
#section-detalhes .btn-adicionar:active {
  animation: gradient 3s infinite;
  transform: scale(0.8);
}

[data-scroll] {
  will-change: transform, scale, opacity;
  opacity: 0;
  transform: translateY(6rem) scale(0.98);
  transition: all 1000ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

[data-scroll=in] {
  opacity: 1;
  transform: translateY(0) scale(1);
}

[data-scroll=out] {
  opacity: 0;
}

.modal {
  background: rgba(42, 42, 42, 0.42);
}
.modal .modal-dialog {
  margin: 0 auto;
}
.modal .modal-dialog .modal-content {
  box-shadow: none;
  border: none;
  border-radius: 9px;
}
.modal .modal-dialog .modal-content .modal-body {
  padding: 2.5rem;
  color: #868584;
}
.modal .modal-dialog .modal-content .modal-body img {
  width: 100%;
  border-radius: 9px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 10px;
}
.modal .modal-dialog .modal-content .modal-body h4 {
  color: #FF6D6D;
  margin-bottom: 10px;
  font-size: 22px;
  font-family: "Sriracha", cursive;
}
.modal .modal-dialog .modal-content .modal-body p {
  margin-bottom: 15px;
}
.modal .modal-dialog .modal-content .modal-body h5 {
  color: #FF6D6D;
  font-size: 1.6rem;
}
.modal .modal-dialog .modal-content .modal-body .form-check {
  padding-left: 1.75rem;
  display: flex;
  align-items: center;
}
.modal .modal-dialog .modal-content .modal-body .form-check .form-check-input {
  margin-left: -1.75rem;
  margin-top: 0.1rem;
}
.modal .modal-dialog .modal-content .modal-body input[type=radio]:before {
  content: "";
  display: block;
  position: absolute;
  border: 1px solid #868584;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.modal .modal-dialog .modal-content .modal-body input[type=radio]:after {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  content: "";
  display: block;
  visibility: visible;
  transition: all 0.1s;
}
.modal .modal-dialog .modal-content .modal-body input[type=radio]:checked:after {
  background-color: #FF5A9D;
}
.modal .modal-dialog .modal-content .modal-body .form-control[type=number]::-webkit-inner-spin-button,
.modal .modal-dialog .modal-content .modal-body .form-control[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal .modal-dialog .modal-content .modal-body textarea {
  border-radius: 6px;
  height: 90px;
  outline: none;
  width: 100%;
  resize: none;
  padding: 5px 8px;
}
.modal .modal-dialog .modal-content .modal-body textarea:focus {
  box-shadow: 0 0 2px 2px #c4a05c96;
}
.modal .modal-dialog .modal-content .modal-body .add {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  border: none;
  color: #ffffff;
  text-decoration: none !important;
  border-radius: 0.8rem;
  font-size: 18px;
  width: 140px;
  height: 45px;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms;
  height: 35px;
  width: 100%;
  margin-left: 20px;
  background-color: #FF5A9D;
  justify-content: space-between;
  padding: 0 40px;
  font-family: "Sriracha", cursive;
}
.modal .modal-dialog .modal-content .modal-body .add:hover {
  box-shadow: inset 14rem 4.8rem 0.3rem rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.modal .modal-dialog .modal-content .modal-body .add span:first-child {
  font-size: 1.4rem;
}
@media (min-width: 576px) {
  .modal .modal-dialog {
    max-width: 420px;
  }
}
@media (max-width: 576px) {
  .modal .modal-dialog .modal-content .modal-body .add {
    font-size: 1.1rem;
    padding: 0 10px;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .modal {
    /* tablets && small laptops */
  }
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.form-group label {
  color: #9e8080;
  font-size: 14px;
  letter-spacing: 0.1rem;
}
.form-group input {
  border-radius: 20px;
  border: 1px solid #D9D9D9;
  padding: 2px 14px;
  height: 35px;
}
.form-group input:focus {
  outline: none;
  border: 1px solid #FF5A9D;
}
.form-group textarea {
  resize: none;
  height: 16rem !important;
  border-radius: 20px;
  padding: 14px 14px;
  font-size: 14px;
}
.form-group textarea:focus {
  outline: none;
  border: 1px solid #FF5A9D;
  box-shadow: none;
}
.form-group small {
  color: !important;
}
.form-group .custom-control-input:checked ~ .custom-control-label::before {
  border: 0.2rem solid;
}
.form-group .custom-control-label {
  left: 0.5rem;
}
.form-group .custom-control-label::before {
  left: -2rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 0.2rem solid;
}
.form-group .custom-control-label::after {
  left: -2rem;
  width: 1.5rem;
  height: 1.5rem;
}

.pagination-wrapper {
  display: block;
  margin: 3rem auto;
}
.pagination-wrapper .pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.pagination-wrapper .pagination li.disabled {
  display: none;
}
.pagination-wrapper .pagination li.active span {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0.2rem;
  border-radius: 0.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  min-width: 40px;
  height: 40px;
  padding: 0 0.5rem;
}
.pagination-wrapper .pagination li span {
  margin: 0 0.5rem;
  font-weight: bold;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1), 200ms;
}
.pagination-wrapper .pagination li a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  font-weight: bold;
  border-radius: 0.6rem;
  min-width: 40px;
  height: 40px;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1), 200ms;
  padding: 0 0.5rem;
  margin: 0 0.5rem;
}
.pagination-wrapper .pagination li a[rel=prev], .pagination-wrapper .pagination li a[rel=next] {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 100%;
}
.pagination-wrapper .pagination li a:hover {
  box-shadow: 0 0 0 0.2rem;
}

.accordion-wrapper {
  width: 70%;
}
@media (max-width: 767.98px) {
  .accordion-wrapper {
    width: 100%;
  }
}
.accordion-wrapper .card {
  margin-bottom: 1rem;
  border-radius: 15px;
  background-color: #FFF5F8;
  box-shadow: 0 3px 6px #ae9ba94f;
  border: none;
}
.accordion-wrapper .card .card-header {
  background-color: #ffbebf95;
  border: 3px solid white;
  border-radius: 15px;
}
.accordion-wrapper .card .card-header .btn {
  font-family: "Sriracha", cursive;
  color: #FF6D6D;
  text-decoration: none;
}
.accordion-wrapper .card .card-header .btn:focus {
  box-shadow: none;
}

.breadcramb {
  padding: 20px 0;
  background-color: #FFF0F0;
}
.breadcramb .content {
  display: flex;
  color: #FF6D6D;
  font-size: 16px;
}
.breadcramb .content p {
  color: #FF6D6D;
  margin-bottom: 0;
}

header {
  transition: ease-in 0.3s all;
}
header .navbar {
  position: fixed;
  z-index: 1040;
  left: 0;
  right: 0;
  top: 0;
  font-size: 1.9rem;
  transition: ease-in 0.3s all;
  background-color: transparent;
  box-shadow: 0 3px 6px #ae9b9b16;
  padding: 1rem 0.5rem;
}
@media (max-width: 991.98px) {
  header .navbar {
    background-color: #ffffff;
    border-bottom: 2px solid #FFF0F0;
  }
  header .navbar .navbar-collapse .navbar-nav .nav-item {
    justify-content: center;
  }
  header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #FF6D6D !important;
  }
}
header .navbar.header-shadow {
  box-shadow: 0 3px 6px #ae9b9b16;
  background-color: #ffffff;
}
header .navbar.header-shadow .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #FF6D6D !important;
}
header .navbar.header-shadow .navbar-collapse .navbar-nav .nav-item .nav-icon {
  background-color: #FF6D6D;
}
header .navbar.header-shadow .navbar-collapse .navbar-nav .nav-item .nav-icon svg {
  color: #ffffff;
}
header .navbar.header-shadow .navbar-collapse .navbar-nav .nav-item .navbar-brand .brand-white-delale {
  display: none;
  transition: ease-in 0.3s all;
}
header .navbar.header-shadow .navbar-collapse .navbar-nav .nav-item .navbar-brand .brand-rose-delale {
  width: 90px;
  display: block;
  transition: ease-in 0.3s all;
}
@media (max-width: 991.98px) {
  header .navbar.header-shadow .navbar-collapse .navbar-nav .nav-item .navbar-brand .brand-rose-delale {
    display: none;
  }
}
header .navbar.small-nav {
  background-color: #ffffff;
  transition: ease-in 0.3s all;
}
header .navbar.small-nav .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #FF6D6D;
}
header .navbar.small-nav .navbar-collapse .navbar-nav .nav-item .navbar-brand .brand-white-delale {
  display: none;
  transition: ease-in 0.3s all;
}
header .navbar.small-nav .navbar-collapse .navbar-nav .nav-item .navbar-brand .brand-rose-delale {
  width: 80px;
  display: block !important;
  transition: ease-in 0.3s all;
}
header .navbar.small-nav .navbar-collapse .navbar-nav .nav-item .nav-icon {
  background-color: #FF6D6D;
}
header .navbar.small-nav .navbar-collapse .navbar-nav .nav-item .nav-icon svg {
  color: #ffffff;
}
header .navbar .navbar-brand {
  transition: ease-in 0.3s all;
}
header .navbar .navbar-brand .brand-colors-delale {
  display: none;
}
@media (max-width: 991.98px) {
  header .navbar .navbar-brand .brand-colors-delale {
    display: block;
    width: 140px;
  }
}
header .navbar .navbar-brand .brand-white-delale {
  transition: ease-in 0.3s all;
  width: 120px;
  display: block;
}
@media (max-width: 991.98px) {
  header .navbar .navbar-brand .brand-white-delale {
    display: none;
  }
}
header .navbar .navbar-brand .brand-rose-delale {
  transition: ease-in 0.3s all;
  display: none;
}
@media (max-width: 991.98px) {
  header .navbar .navbar-collapse {
    margin: inherit;
  }
}
header .navbar .navbar-collapse .navbar-nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
header .navbar .navbar-collapse .navbar-nav .nav-item {
  display: flex;
  align-items: center;
  /*  &.active {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 72px;
      height: 2px;
      width: 100%;
      transform: translateX(-50%);
      background-color: map-get($colors, pink-50 );
    }
  } */
}
header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  font-family: "Sriracha", cursive;
  transition: ease-in 0.3s all;
}
header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link:hover {
  color: #9e8080;
}
header .navbar .navbar-collapse .navbar-nav .nav-item .nav-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
header .navbar .navbar-collapse .navbar-nav .nav-item .nav-icon svg {
  color: #FF6D6D;
}
header .navbar .navbar-collapse .navbar-nav .nav-item.cart-icon {
  position: relative;
}
header .navbar .navbar-collapse .navbar-nav .nav-item.cart-icon > div {
  position: absolute;
  top: 40%;
  right: -10px;
  transform: translate(50%, -50%);
}
header .navbar .navbar-collapse .navbar-nav .nav-item.cart-icon > div img {
  width: 28px;
}
header .navbar .navbar-collapse .navbar-nav .nav-item.cart-icon > div span {
  position: absolute;
  transform: translate(50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  right: -10%;
  top: 24%;
  width: 20px;
  height: 20px;
  background: #c49f5c;
  color: #fff;
  border-radius: 50%;
  font-size: 13px;
}
header .navbar .navbar-collapse .navbar-nav .nav-item input {
  color: #FFDADA;
}
header .navbar .button-wrapper {
  cursor: pointer;
}
header .navbar .button-wrapper .navbar-toggler svg {
  color: #FF6D6D;
}

footer .wrapper {
  padding: 4rem 0 0;
  border-top: 2px solid #fff;
  background-color: #FFF0F0;
}
footer .wrapper .img-fluid {
  width: 90%;
}
@media (max-width: 575.98px) {
  footer .wrapper .img-fluid {
    width: 50%;
  }
}
footer .wrapper a.link,
footer .wrapper p.title {
  font-family: "Sriracha", cursive;
  font-size: 18px;
  color: #FF6D6D;
  text-decoration: none;
}
footer .wrapper a.icon {
  color: #9e8080;
  font-size: 24px;
}
footer .wrapper .copyright {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ffdada;
}
@media (max-width: 767.98px) {
  footer .wrapper .copyright {
    flex-direction: column;
    align-items: center;
  }
}
footer .wrapper .copyright p {
  color: #9e8080;
  margin-bottom: 0px;
}

.btn-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  border: none;
  color: #ffffff;
  text-decoration: none !important;
  border-radius: 0.8rem;
  font-size: 18px;
  width: 140px;
  height: 45px;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms;
}
.btn-theme:hover {
  box-shadow: inset 14rem 4.8rem 0.3rem rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.btn-gradient {
  background: linear-gradient(-45deg, #ff6d6d, #fb999a, #ff82b4, #ff6d6d);
  background-size: 800% 400%;
  padding: 1rem 2rem;
  display: inline-block;
  border: none;
  border-radius: 30px;
  font-size: 17px;
  font-family: "Sriracha", cursive;
  font-weight: 300;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;
}
.btn-gradient:hover {
  animation: gradient 3s infinite;
  transform: scale(1.05);
}
.btn-gradient:active {
  animation: gradient 3s infinite;
  transform: scale(0.8);
}

.btn-whatsapp {
  background: #25D366;
  padding: 1rem 2rem;
  display: inline-block;
  border: none;
  border-radius: 30px;
  font-size: 17px;
  font-family: "Sriracha", cursive;
  font-weight: 300;
  color: white;
  transition: all 0.3s ease-in-out;
}
.btn-fix-cart {
  position: fixed;
  right: 14px;
  bottom: 46px;
  background-color: #FF817C;
  border-radius: 50%;
  width: 85px;
  height: 85px;
  z-index: 1040;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  transition: ease-in 0.3s all;
}
.btn-fix-cart:hover {
  background-color: #ff5b5b;
}
@media (max-width: 575.98px) {
  .btn-fix-cart {
    width: 65px;
    height: 65px;
    bottom: 89px;
  }
  .btn-fix-cart img {
    width: 40px;
  }
}
.btn-fix-cart .value {
  position: absolute;
  background-color: #FF6D6D;
  padding: 5px;
  font-family: "Sriracha", cursive;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  transform: translate(30px, -29px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px #ae9b9b16;
}
@media (max-width: 575.98px) {
  .btn-fix-cart .value {
    transform: translate(26px, -25px);
  }
}

.btn-outline {
  background: #ffffff;
  padding: 1rem 2rem;
  border: none;
  border-radius: 30px;
  border: 1px solid #FF6D6D;
  font-size: 17px;
  font-family: "Sriracha", cursive;
  font-weight: 300;
  color: #FF6D6D;
  transition: all 0.3s ease-in-out;
}
.btn-outline:hover {
  text-decoration: none;
  color: #FF5A9D;
  border: 1px solid #FF5A9D;
}
.btn-outline:focus {
  background-color: #FFF0F0;
}

.navbar-toggler {
  outline: none;
  border: none;
  height: 40px;
  padding: 0.25rem 0;
}
.navbar-toggler .menu-bar {
  display: block;
  height: 2px;
  width: 20px;
  margin: 7.5px 0;
  margin-left: auto;
  cursor: pointer;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1), 200ms 200ms;
}
.navbar-toggler .menu-bar.bar-1 {
  position: relative;
  top: 0;
  right: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 33px;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1), 200ms 200ms;
}
.navbar-toggler .menu-bar.bar-2 {
  position: relative;
  top: 0;
  right: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 33px;
  transition: cubic-bezier(0.165, 0.84, 0.44, 1), 200ms 200ms;
}
.navbar-toggler .menu-bar.bar-3 {
  position: relative;
  top: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 33px;
  -webkit-transition: cubic-bezier(0.175, 0.885, 0.32, 1.275), 600;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275), 600;
}
.navbar-toggler.active {
  height: 40px;
}
.navbar-toggler.active .menu-bar.bar-1 {
  top: 1rem;
  right: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.navbar-toggler.active .menu-bar.bar-2 {
  top: 0;
  right: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.navbar-toggler.active .menu-bar.bar-3 {
  top: 0;
  right: -10rem;
  opacity: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.card-product {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 18px 18px 14px 18px;
  position: relative;
  border-radius: 6px;
  margin-bottom: 2rem;
}
.card-product.ready::before {
  content: url("/img/elements/pronta_entrega.png");
  width: 60px;
  height: 60px;
  position: absolute;
  display: block;
  left: 5px;
  top: 5px;
}
.card-product .product-image {
  width: 100%;
  height: 200px;
  border-radius: 9px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 1rem;
}
.card-product .product-description {
  position: relative;
  padding: 20px;
  background-color: #FB999A;
  border-radius: 0 0 11px 11px;
  transition: ease-in 0.3s all;
}
.card-product .product-description:hover {
  background-color: #f88688;
  cursor: pointer;
}
.card-product .product-description h4 {
  color: #ffffff;
  font-size: 24px;
  font-family: "Sriracha", cursive;
}
.card-product .product-description p {
  margin-bottom: 12px;
  color: #ffffff;
  font-family: "Sriracha", cursive;
}
.card-product .product-description p.description-text {
  height: 45px;
}
.card-product .product-description h3 {
  color: #ffffff;
  font-size: 2.3rem;
  margin-bottom: 20px;
  font-family: "Sriracha", cursive;
}
.card-product .product-description button {
  position: absolute;
  transform: translate(-50%, 45%);
  bottom: 0;
  left: 50%;
  width: max-content;
}

.card-loja {
  display: flex;
  flex-direction: column;
  background-color: #FB999A;
  padding: 0 10px 10px 10px;
  position: relative;
  border-radius: 10px;
  margin-bottom: 2rem;
  transition: 0.5s ease-out;
}
.card-loja:hover {
  border-color: #ff5a9c88;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}
.card-loja:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}
.card-loja .loja-image {
  height: 260px;
  background-position: center;
  border-radius: 0 0 10px 10px;
}
@media (max-width: 575.98px) {
  .card-loja .loja-image {
    height: 32rem;
  }
}
.card-loja .loja-content {
  margin-top: 1rem;
  background-color: #ffffff;
  padding: 10px 10px 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card-loja .loja-content .loja-title {
  font-family: "Sriracha", cursive;
  font-size: 18px;
  text-align: center;
  color: #FF6D6D;
}
.card-loja .loja-content .loja-text {
  font-family: "Sriracha", cursive;
  color: #FF6D6D;
  text-align: center;
  margin-bottom: 0px;
}
.card-loja .loja-content .loja-phone {
  font-family: "Sriracha", cursive;
  color: #FF6D6D;
  text-align: center;
}
.card-loja .card-button {
  transform: translate(-50%, 125%);
  border-radius: 30px;
  border: 2px solid #FB999A;
  background-color: #ffffff;
  color: #FF6D6D;
  font-family: "Sriracha", cursive;
  font-size: 16px;
  padding: 1rem 2rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
  width: max-content;
}
@media (max-width: 575.98px) {
  .card-loja .card-button {
    opacity: 1;
    transform: translate(-50%, 50%);
  }
}

.card-combo {
  display: flex;
  flex-direction: column;
  border: 1px solid #D3D3D3;
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  border-radius: 10px;
  height: 510px;
  margin-bottom: 2rem;
  transition: 0.5s ease-out;
}
.card-combo:hover {
  border-color: #ff5a9c88;
  box-shadow: 0 4px 18px 0 rgba(230, 86, 158, 0.25);
}
.card-combo:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}
.card-combo .combo-image {
  height: 300px;
  background-position: center;
  border-radius: 10px;
}
@media (max-width: 575.98px) {
  .card-combo .combo-image {
    height: 200px;
  }
}
.card-combo .combo-content {
  padding: 2rem;
  height: 130px;
}
.card-combo .combo-content .combo-title {
  font-family: "Sriracha", cursive;
  font-size: 18px;
  color: #FF6D6D;
}
.card-combo .combo-content .combo-subtitle {
  font-family: "Sriracha", cursive;
  margin-bottom: 0px;
}
.card-combo .combo-footer {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 575.98px) {
  .card-combo .combo-footer {
    flex-direction: column-reverse;
  }
  .card-combo .combo-footer .value {
    margin-bottom: 1rem;
  }
}
.card-combo .combo-footer a {
  text-decoration: none;
}
.card-combo .combo-footer .value {
  display: flex;
  align-items: center;
}
.card-combo .combo-footer .value p {
  color: #FF6D6D;
  margin-bottom: 0;
  font-family: "Sriracha", cursive;
  font-size: 22px;
}

.card-clientes-depoimentos {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 2rem;
  position: relative;
  border-radius: 10px;
  transition: 0.5s ease-out;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}
.card-clientes-depoimentos:hover {
  border-color: #ff5a9c88;
  box-shadow: 0 4px 18px 0 rgba(230, 86, 158, 0.25);
}
.card-clientes-depoimentos:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}
.card-clientes-depoimentos .card-clientes-header {
  border-bottom: 1px solid #D3D3D3;
}
.card-clientes-depoimentos .card-clientes-header p {
  font-family: "Sriracha", cursive;
  font-size: 18px;
  color: #FF6D6D;
}
.card-clientes-depoimentos .card-clientes-image img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.card-clientes-depoimentos .card-clientes-footer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-family: "Sriracha", cursive;
  font-size: 18px;
  color: #FF6D6D;
}
.card-clientes-depoimentos .card-clientes-footer p {
  margin-bottom: 0;
}

.card-swiper-detalhe {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  background-color: #ffffff;
  position: relative;
  border-radius: 10px;
  transition: 0.5s ease-out;
}
.card-swiper-detalhe:hover {
  cursor: pointer;
}
.card-swiper-detalhe:hover .card-swiper-img {
  border-color: #ff5a9c88;
  box-shadow: 0 4px 18px 0 rgba(230, 86, 158, 0.25);
}
.card-swiper-detalhe .card-swiper-img {
  transition: 0.5s ease-out;
  padding: 1rem;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  border-radius: 10px;
}
.card-swiper-detalhe .card-swiper-img img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
.card-swiper-detalhe .card-swiper-detalhe-dec {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.card-swiper-detalhe .card-swiper-detalhe-dec .card-title {
  color: #242424;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-swiper-detalhe .card-swiper-detalhe-dec .card-value {
  color: #242424;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.section-hero {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991.98px) {
  .section-hero {
    height: 57vh;
  }
}
@media (max-width: 767.98px) {
  .section-hero {
    height: 40vh;
  }
}

.section-quem-somos {
  position: relative;
  background-color: #fff0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 630px;
  background-position: center;
  margin-bottom: 10px;
}
@media (max-width: 575.98px) {
  .section-quem-somos {
    padding: 3rem 0;
    height: fit-content;
  }
}
.section-quem-somos .quem-somos-content {
  padding: 1rem 3rem;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  background-color: #ffffffc7;
  margin: 0 auto !important;
  text-align: center !important;
  width: 90% !important;
}
.section-quem-somos .quem-somos-content .text-quem-somos {
  color: #939393;
}
@media (max-width: 991.98px) {
  .section-quem-somos .quem-somos-content {
    background-color: #ffffffc7;
    border-radius: 10px;
  }
}

.section-cardapio {
  padding: 10rem 0;
  border-bottom: 1px solid #D3D3D3;
}
@media (max-width: 575.98px) {
  .section-cardapio {
    padding: 4rem 0;
  }
}
@media (max-width: 991.98px) {
  .section-cardapio .cardapio-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
}

.section-clientes {
  padding: 8rem 0;
}
@media (max-width: 575.98px) {
  .section-clientes {
    padding: 4rem 0;
  }
}
@media (max-width: 991.98px) {
  .section-clientes .clientes-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
}

.section-recado {
  background-color: #F9F9F9;
  padding: 3rem 0;
}
@media (max-width: 1199.98px) {
  .section-recado .recado-content {
    text-align: center;
    margin-bottom: 2rem;
  }
}
.section-recado .file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.section-recado .file-input__label {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 50px;
  height: 50px;
  align-items: center;
  border-radius: 42px;
  font-size: 14px;
  font-weight: 600;
  color: #FF6D6D;
  font-weight: 100;
  font-size: 14px;
  padding: 10px 12px;
  border: 1px solid #FF6D6D;
}
.section-recado .file-input__label svg {
  height: 16px;
  color: #FF6D6D;
}
.section-recado .form-control {
  min-height: 50px;
  padding: 0 2rem;
  color: #9e8080;
  font-family: "Sriracha", cursive;
  font-size: 15px;
  border: 1px solid #cecece;
  border-radius: 42px;
  background-color: transparent;
  margin-right: 1rem;
}
.section-recado .form-control:focus, .section-recado .form-control:focus-visible {
  border-color: #FFDADA;
  outline: none;
  color: #9e8080;
  box-shadow: none;
}
.section-recado .preview-image {
  width: 100px;
  height: 100px;
  position: relative;
}
.section-recado .preview-image img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: cover;
}
.section-recado .preview-image .btn-outline {
  position: absolute;
  right: 10px;
  padding: 0rem 0.6rem;
}
.section-recado .preview-image .image-container {
  width: 100px;
  height: 100px;
}

.section-instagram {
  padding: 3rem 0;
}
.section-instagram img {
  transition: ease-in 0.3s all;
}
.section-instagram img:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.section-faq {
  padding: 8rem 0;
}
@media (max-width: 575.98px) {
  .section-faq {
    padding: 4rem 0;
  }
}

.section-combos .combos-top-page {
  padding: 2rem 0;
  background-color: #FF817C;
}
.section-combos .nav-pills {
  margin-top: 3rem;
  margin-bottom: 6rem;
}
.section-combos .nav-pills .nav-item {
  background-color: #ffffff;
  border: 1px solid #FB999A;
  width: 200px;
  margin-right: 1rem;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .section-combos .nav-pills .nav-item {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.section-combos .nav-pills .nav-item .nav-link {
  text-align: center;
  border-radius: 0;
  font-family: "Sriracha", cursive;
  padding: 1rem 0;
}
.section-combos .nav-pills .nav-item .nav-link.active {
  background-color: #FB999A;
}
.section-combos .tab-content .tab-pane {
  font-family: "Sriracha", cursive;
  color: #939393;
}

.section-spotify {
  background-color: #FFF0F0;
}
@media (max-width: 1199.98px) {
  .section-spotify {
    padding-top: 2rem;
  }
}
.section-spotify img {
  transform: translateY(110px);
}
@media (max-width: 1199.98px) {
  .section-spotify img {
    transform: translateY(0px);
  }
}
.section-spotify .spotify-title {
  font-family: "Sriracha", cursive;
  font-size: 28px;
  color: #FF6D6D;
}
.section-spotify .spotify-subtitle {
  font-family: "Sriracha", cursive;
  font-size: 18px;
  color: #2B2B2B;
}
@media (max-width: 768.98px) {
  .section-spotify .spotify-title, .section-spotify .spotify-subtitle {
    text-align: center;
  }
}

.encomendas-wrapper {
  padding-top: 2rem;
}

.quemSomos-wrapper {
  background-color: #FFF0F0;
}
.quemSomos-wrapper .img-equipe {
  height: 300px;
  background-position: inherit;
  border: 3px solid #FB999A;
}
@media (max-width: 575.98px) {
  .quemSomos-wrapper .img-equipe {
    height: 220px;
  }
}

.lojas-wrapper {
  background-color: #FFF0F0;
  background-position: top;
  background-size: auto;
  background-repeat: no-repeat;
}
.lojas-wrapper .lojas-title {
  color: #ffffff;
  font-family: "Sriracha", cursive;
  font-size: 36px;
  transition: ease-in 0.3s all;
}
@media (max-width: 767.98px) {
  .lojas-wrapper .lojas-title {
    font-size: 30px;
  }
}

.clientes-wrapper .box-img {
  width: 100px;
  height: 100px;
}
.clientes-wrapper .box-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}
.clientes-wrapper .clientes-mural-top {
  height: 250px;
  text-align: center;
}
.clientes-wrapper .clientes-mural-top .clientes-title {
  font-size: 28px;
  color: #ffffff;
  font-family: "Sriracha", cursive;
  margin-bottom: 0;
}
.clientes-wrapper .clientes-mural-top .clientes-subtitle {
  font-size: 18px;
  color: #ffffff;
  font-family: "Sriracha", cursive;
}
.clientes-wrapper .clientes-mural-wrapper {
  padding: 2rem 0;
  background-size: auto;
}
.clientes-wrapper .clientes-mural-wrapper .clientes-mural-transform-translate {
  transform: translateY(-85px);
}
@media (max-width: 575.98px) {
  .clientes-wrapper .clientes-mural-wrapper .clientes-mural-transform-translate {
    transform: translateY(0px);
  }
}

.swiperHero {
  height: 400px;
  border: 4px solid white;
  margin-top: 120px;
}
@media (max-width: 991.98px) {
  .swiperHero {
    height: 250px;
  }
}
@media (max-width: 767.98px) {
  .swiperHero {
    height: 167px;
  }
}
@media (max-width: 575.98px) {
  .swiperHero {
    height: 138px;
    margin-top: 90px;
  }
}
.swiperHero .swiper-wrapper .swiper-slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiperHero .swiper-wrapper .swiper-slide a {
  text-decoration: none;
}
.swiperHero .swiper-wrapper .swiper-slide .slide-title, .swiperHero .swiper-wrapper .swiper-slide .slide-subtitulo {
  color: #ffffff;
  font-family: "Sriracha", cursive;
}
.swiperHero .swiper-wrapper .swiper-slide .slide-title {
  font-size: 50px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .swiperHero .swiper-wrapper .swiper-slide .slide-title {
    font-size: 26px;
  }
}
.swiperHero .swiper-wrapper .swiper-slide .slide-subtitulo {
  font-size: 30px;
  text-align: center;
}
@media (max-width: 767.98px) {
  .swiperHero .swiper-wrapper .swiper-slide .slide-subtitulo {
    font-size: 16px;
  }
}
.swiperHero .swiper-button-prev, .swiperHero .swiper-button-next {
  color: white;
  top: 93%;
  background-color: #FF6D6D;
  width: 40px;
  height: 40px;
}
@media (max-width: 991.98px) {
  .swiperHero .swiper-button-prev, .swiperHero .swiper-button-next {
    top: 91%;
  }
}
@media (max-width: 575.98px) {
  .swiperHero .swiper-button-prev, .swiperHero .swiper-button-next {
    display: none;
  }
}
.swiperHero .swiper-button-prev::after, .swiperHero .swiper-button-next::after {
  font-size: 16px;
}
.swiperHero .swiper-button-prev {
  left: auto;
  right: 50%;
  border-radius: 20px 0 0 20px;
}
.swiperHero .swiper-button-next {
  left: 50%;
  right: auto;
  border-radius: 0 20px 20px 0;
}

.swiperCardapio {
  height: 200px;
  border: 4px solid white;
}
.swiperCardapio .swiper-wrapper .swiper-slide {
  border-radius: 10px;
  background-position: center;
}
.swiperCardapio .swiper-pagination-bullet-active {
  background-color: #FF6D6D;
}
.swiperCardapio .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: #FF6D6D;
}

.swiperClientes {
  height: 200px;
  border: 4px solid white;
}
.swiperClientes .swiper-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiperClientes .swiper-wrapper .swiper-slide .swiper-testimonial-wrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.swiperClientes .swiper-wrapper .swiper-slide .swiper-testimonial-wrapper .testimonial-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 2rem;
  background-position: center;
}
.swiperClientes .swiper-wrapper .swiper-slide .swiper-testimonial-wrapper .testimonial-content {
  flex: 1;
}
.swiperClientes .swiper-wrapper .swiper-slide .swiper-testimonial-wrapper .testimonial-content .testimonial-title {
  font-family: "Sriracha", cursive;
  color: #2B2B2B;
  margin-bottom: 0px;
  font-size: 18px;
}
.swiperClientes .swiper-wrapper .swiper-slide .swiper-testimonial-wrapper .testimonial-content .testimonial-subtitle {
  font-size: 10px;
  color: #2B2B2B;
}
.swiperClientes .swiper-wrapper .swiper-slide .swiper-testimonial-wrapper .testimonial-content .testimonial-text {
  color: #2B2B2B;
}
.swiperClientes .swiper-pagination-bullet-active {
  background-color: #FF6D6D;
}
.swiperClientes .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: #FF6D6D;
}
.swiperClientes .swiper-button-prev, .swiperClientes .swiper-button-next {
  color: #FF6D6D;
  top: 93%;
  width: 40px;
  height: 40px;
}
.swiperClientes .swiper-button-prev::after, .swiperClientes .swiper-button-next::after {
  font-size: 16px;
}
.swiperClientes .swiper-button-prev {
  left: auto;
  right: 50%;
  border-radius: 20px 0 0 20px;
}
.swiperClientes .swiper-button-next {
  left: 50%;
  right: auto;
  border-radius: 0 20px 20px 0;
}

.swiperCombos {
  height: 300px;
}
@media (max-width: 767.98px) {
  .swiperCombos {
    height: 200px;
  }
}
@media (max-width: 575.98px) {
  .swiperCombos {
    height: 120px;
  }
}
.swiperCombos .swiper-wrapper .swiper-slide {
  width: 60%;
  border-radius: 10px;
  background-position: center;
  display: flex;
  align-items: center;
}
.swiperCombos .swiper-wrapper .swiper-slide::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #fff0f000, #00000003, #00000085);
  border-radius: 10px;
}
.swiperCombos .swiper-wrapper .swiper-slide p {
  font-size: 42px;
  font-family: "Sriracha", cursive;
  color: #ffffff;
  z-index: 3;
  margin-left: 2rem;
}
@media (max-width: 767.98px) {
  .swiperCombos .swiper-wrapper .swiper-slide p {
    font-size: 28px;
  }
}

.swiperDetalheproduto {
  padding: 20px 5px;
  height: 200px;
  border: 4px solid white;
}
.swiperDetalheproduto .swiper-pagination-bullet-active {
  background-color: #FF6D6D;
}
.swiperDetalheproduto .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: #FF6D6D;
}