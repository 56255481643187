// Buttons
@mixin btn-theme {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: none;
  border: none;
  color: map-get($colors, white-50);
  text-decoration: none !important;
  border-radius: 0.8rem;
  font-size: 18px;
  width: 140px;
  height: 45px;
  transition: $smooth $time;

  &:hover {
    box-shadow: inset 14rem 4.8rem 0.3rem rgba(255, 255, 255, 0.1);
    color: map-get($colors, white-50);
  }
}

@mixin btn-gradient {
  background: linear-gradient(-45deg, #ff6d6d, #fb999a, #ff82b4, #ff6d6d);
  background-size: 800% 400%;
  padding: 1rem 2rem;
  display: inline-block;
  border: none;
  border-radius: 30px;
  font-size: 17px;
  font-family: $Sriracha;
  font-weight: 300;
  color: map-get($colors, white-50);
  transition: all 0.5s ease-in-out;
  animation: gradient 10s infinite cubic-bezier(0.62, 0.28, 0.23, 0.99) both;

  &:hover {
    animation: gradient 3s infinite;
    transform: scale(1.05);
  }

  &:active {
    animation: gradient 3s infinite;
    transform: scale(0.8);
  }
}

@mixin btn-whatsapp {
  background: #25D366;
  padding: 1rem 2rem;
  display: inline-block;
  border: none;
  border-radius: 30px;
  font-size: 17px;
  font-family: $Sriracha;
  font-weight: 300;
  color:white;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: map-get($colors, green-500);
  }

}

@mixin btn-outline {
  background: map-get($colors, white-50);
  padding: 1rem 2rem;
  border: none;
  border-radius: 30px;
  border: 1px solid map-get($colors, pink-50);
  font-size: 17px;
  font-family: $Sriracha;
  font-weight: 300;
  color: map-get($colors, pink-50);
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
    color: map-get($colors, pink-200);
    border: 1px solid map-get($colors, pink-200);
  }
  &:focus {
    background-color: map-get($colors, pink-baby);
  }
}

@mixin btn-fix-cart {
  position: fixed;
  right: 14px;
  bottom: 46px;
  background-color: map-get($colors, pink-400);
  border-radius: 50%;
  width: 85px;
  height: 85px;
  z-index: 1040;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  transition: ease-in 0.3s all;

  &:hover {
    background-color: #ff5b5b;
  }

  @media (max-width: 575.98px) {
    width: 65px;
    height: 65px;
    bottom: 89px;

    img {
      width: 40px;
    }
  }

  .value {
    position: absolute;
    background-color: map-get($colors, pink-50);
    padding: 5px;
    font-family: $Sriracha;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: map-get($colors, white-50);
    transform: translate(30px, -29px);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 6px #ae9b9b16;

    @media (max-width: 575.98px) {
      transform: translate(26px, -25px);
    }
  }
}
