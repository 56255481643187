@mixin section-spotify {
  background-color: map-get($colors, pink-baby );
  @media (max-width: 1199.98px){
    padding-top: 2rem;
  }
  img {
    transform: translateY(110px);
    @media (max-width: 1199.98px){
      transform: translateY(0px);
    }
  }

  .spotify-title {
    font-family: $Sriracha;
    font-size: 28px;
    color: map-get($colors, pink-50 );

   
  }

  .spotify-subtitle {
    font-family: $Sriracha;
    font-size: 18px;
    color: map-get($colors, black-50);
  }

  @media (max-width: 768.98px){
    .spotify-title, .spotify-subtitle {
      text-align: center;
    }
  }
}