@mixin section-quem-somos {
  position: relative;
  background-color: #fff0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 630px;
  background-position: center;
  margin-bottom: 10px;

  @media (max-width: 575.98px) {
    padding: 3rem 0;
    height: fit-content;
  }

  .quem-somos-content {
    padding: 1rem 3rem;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    background-color: #ffffffc7;
    margin: 0 auto !important;
    text-align: center !important;
    width: 90% !important;

    .text-quem-somos {
      color: map-get($colors, gray-50);
    }

    @media (max-width: 991.98px) {
      background-color: #ffffffc7;
      border-radius: 10px;
    }
  }
}
