// Typography //
$ff: 'Roboto', sans-serif;
$Sriracha: 'Sriracha', cursive;

// Colors //
$colors: (
  primary: #481B08,
  secondary: #C49F5C,
  bg: #E9DFCA,
  light: #F4F4F4,
  grey: #C4C4C4,
  ghost: rgba(0,0,0,.0)
);
  
  
// Colors Delalê//
  $colors: (
    gray-50: #939393,
    gray-100: #D3D3D3,
    black-50: #2B2B2B,
    white-50: #ffffff,
    white-100: #F9F9F9,
    pink-baby: #FFF0F0,
    pink-50: #FF6D6D,
    pink-100: #FFDADA,
    pink-200: #FF5A9D,
    pink-300: #FB999A,
    pink-400: #FF817C,
    wood-50: #9e8080,
  );


// Transitions //
$time: 200ms;
$ease: cubic-bezier(0.165, 0.84, 0.44, 1);
$smooth: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$transition: $ease, $time;
