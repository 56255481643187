// main code
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin form-style {
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    color: map-get($colors, wood-50);
    font-size: 14px;
    letter-spacing: .1rem;
  }

  input {
    border-radius: 20px;
    border: 1px solid #D9D9D9;
    padding: 2px 14px;
    height: 35px;
    &:focus {
      outline: none;
      border: 1px solid map-get($colors, pink-200);
    }
  }

  textarea {
    resize: none;
    height: 16rem !important;
    border-radius: 20px;
    padding: 14px 14px;
    font-size: 14px;
    &:focus {
      outline: none;
      border: 1px solid map-get($colors, pink-200);
      box-shadow: none;
    }
  }

  small { color: map-get($colors, grey) !important; }

  
  .custom-control-input {
    &:checked~ {
      .custom-control-label {
        &::before {
          background-color: map-get($colors, primary);
          border: .2rem solid map-get($colors, primary);
          color: map-get($colors, white);
        }
      }
    }
  }

  .custom-control-label {
    left: .5rem;

    &::before {
      left: -2rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      background-color: map-get($colors, white);
      border: .2rem solid map-get($colors, grey);
    }

    &::after {
      left: -2rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
