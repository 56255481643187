@mixin swiperClientes {
  height: 200px;
  border: 4px solid white;

  .swiper-wrapper {
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      .swiper-testimonial-wrapper {
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .testimonial-img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-right: 2rem;
          background-position: center;
        }
        .testimonial-content {
          flex: 1;
          .testimonial-title {
            font-family: $Sriracha;
            color: map-get($colors, black-50 );
            margin-bottom: 0px;
            font-size: 18px;
          }
          .testimonial-subtitle {
            font-size: 10px;
            color: map-get($colors, black-50 );
          }
          .testimonial-text {
            color: map-get($colors, black-50 );
          }
        }
      }
    }
  }

  .swiper-pagination-bullet-active {
    background-color: map-get($colors, pink-50 );
  }

  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    background-color: map-get($colors, pink-50 );
  }
  
  .swiper-button-prev, .swiper-button-next {
    color: map-get($colors, pink-50 );
    top: 93%;
    width: 40px;
    height: 40px;

    &::after {
      font-size: 16px;
    }
  }

  .swiper-button-prev {
    left: auto;
    right: 50%;
    border-radius: 20px 0 0 20px;
  }
  .swiper-button-next {
    left: 50%;
    right: auto;
    border-radius: 0 20px 20px 0;
  }
}