@mixin clientes-wrapper {
  .box-img {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50px;
    }
  }
  .clientes-mural-top {
    height: 250px;
    text-align: center;

    .clientes-title {
      font-size: 28px;
      color: map-get($colors, white-50);
      font-family: $Sriracha;
      margin-bottom: 0;
    }

    .clientes-subtitle {
      font-size: 18px;
      color: map-get($colors, white-50);
      font-family: $Sriracha;
    }
  }
  .clientes-mural-wrapper {
    padding: 2rem 0;
    background-size: auto;
    .clientes-mural-transform-translate {
      transform: translateY(-85px);

      @media (max-width: 575.98px) {
        transform: translateY(0px);
      }
    }
  }
}
