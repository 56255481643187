@mixin accordion-wrapper {
  width: 70%;

  @media (max-width: 767.98px){
    width: 100%;
  }
  .card {
    margin-bottom: 1rem;
    border-radius: 15px;
    background-color: #FFF5F8;
    box-shadow: 0 3px 6px #ae9ba94f;
    border: none;
    .card-header {
      background-color: #ffbebf95;
      border: 3px solid white;
      border-radius: 15px;

      .btn {
        font-family: $Sriracha;
        color: map-get($colors, pink-50 );
        text-decoration: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
