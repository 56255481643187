@mixin lojas-wrapper {
  background-color: map-get($colors, pink-baby );
  background-position: top;
  background-size: auto;
  background-repeat: no-repeat;

  .lojas-title {
    color: map-get($colors, white-50 );
    font-family: $Sriracha;
    font-size: 36px;
    transition: ease-in 0.3s all;
  
    @media (max-width: 767.98px){
      font-size: 30px;
    }
  }
}