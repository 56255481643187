// Structure //
.erro-404-wrapper {
 padding-top: 4rem;
 margin-top: 12rem;
 height: 60vh;
 position: relative;
 display: flex;
 align-items: center;
 @media (max-width: 991.98px){
  margin-top: 7rem;
 }
 img {
  bottom: -90px;
  width: 500px;
  @media (max-width: 767.98px){
    bottom: -49px;
    width: 270px;
  }
  @media (max-width: 475.98px){
    display: none;
  }
 }

 .content-aviso {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title{
    font-size: 100px;
    font-family: $Sriracha;
    color: map-get($colors, pink-50);
    text-align: center;
    margin-bottom: 0;
    line-height: 71px;
  }
  .subtitle {
    font-size: 30px;
    color: map-get($colors, pink-50);
    font-family: $Sriracha;
    text-align: center;
  }
 }
}

.quantity {
  display: flex;
  input {
    height: 35px;
    width: 40px;
    flex: none;
    text-align: center;
    font-size: 1.8rem;
    border: 1px solid #E8E8E8;
    border-right: none;
    border-left: none;
    padding: .375rem 0;
    outline: none;
  }

  button {
    border: none;
    padding: 0 8px;
    color: map-get($colors, pink-200 );
    font-size: 1.2rem;
    outline: none;
    &.btn-minus {
      border-right: none;
      border-radius:  6px 0 0 6px ;
    }
    &.btn-plus {
      border-left: none;
      border-radius: 0 6px 6px 0;
    }
  }
}

.categories-navbar {
  background-position: left top;
  background-repeat: repeat-x;
  background-size: contain;
  height: 107px;
  display: flex;
  align-items: center;

  &.sticky-nav {
    top: 100px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1039;
  }

  ul {
    margin-top: -6px;
    padding: 0;
    list-style: none;
    display: flex;

    li {
      color: map-get($colors, pink-100);
      opacity: .41;
      font-family: $ff;
      font-size: 1.6rem;
      margin-right: 60px;
      transition: all 0.2s;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        opacity: 1;
      }

      a:hover {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

#fixed-whatsapp-button {
  position: fixed;
  right: 10px;
  bottom: 70px;
  z-index: 1040;
  img {
    width:60px;
  }
}

#cart {
  padding: 50px 0;
  background-color: map-get($colors, white-50 );

  .title {
    margin-bottom: 25px;
    color: map-get($colors, pink-50 );
    font-family: $Sriracha;
  }

  table.products {
    th {
      border-top: none;
      color: #2B2B2B;
      font-weight: 600;
    }

    tbody {
      td {
        padding-top: 1rem;
        padding-bottom: 1.8rem;
      }

      .product {
        display: flex;
        align-items: center;

        img {
          border-radius: 1px;
          width: 100px;
          height: 100px;
          margin-right: 8px;
          object-fit: cover;
          object-position: center;
        }
      }

      .price {
        font-family: $Sriracha;
        font-size: 18px;
      }
    }
  }

  .form-control[type="number"]::-webkit-inner-spin-button,
  .form-control[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .schedule-wrapper {
    .schedule-options-title {
      display: flex;
      align-items: center;
      svg {
        color: map-get($colors, pink-200 );
        font-size: 3rem;
        margin-right: 5px;
      }
    }
    .schedule {
      padding: 30px 20px;  
      .owl-nav {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-10px,-115%);
        display: flex;
        align-items: center;
  
        button {
          height: 22px;
          display: flex;
          align-items: center;
          border: 1px solid transparent;
  
          &:focus {
            outline: none;
            border: 1px solid #C49F5C60;
          }
  
          svg {
            color: map-get($colors, pink-200 );
  
            font-size: 2.2rem;
            margin: 0px 6px;
            transition: all .2s;
          }
        }
      }
  
      .card-schedule {
        border: 1px solid #D9D9D9;
        border-radius: 6px;
        transition: border .1s;
        cursor: pointer;
        margin-inline: 2px;
        min-height: 40px;
        display: flex;
  
        &.active {
          border-color: map-get($colors, pink-200 );
          background-color: map-get($colors, pink-200 );
  
          label {
            color: map-get($colors, white-50 );
          }
        }
  
        label {
          padding: 10px 0;
          position: relative;
          margin: 0;
          width: 100%;
          height: 100%;
          align-items: center;
          display: flex;
          justify-content: center;
          cursor: pointer;
  
          input[type="radio"] {
            display: none;
          }
        }
      }
    }
  }
 
  .address-data {
    padding: 30px 20px;
    .alert-warning {
      background-color: map-get($colors, pink-100 );
      border-radius: 10px;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.1) 2px 8px 8px;


      svg {
        color: map-get($colors, pink-200 );
      }

      p {
        font-size: 18px;
        color: map-get($colors, black-50 );

        strong {
          color: map-get($colors, wood-50 );
        }

        span {
          color: map-get($colors, pink-200 );
        }
      }

      li {
        color: map-get($colors, pink-200 );
        font-family: $Sriracha;
        font-size: 18px;
      }
    }
  }

  .credit-card {
    padding: 30px 20px;
  }

  .resume {
    margin-bottom: 18px;
    padding: 40px 20px 20px;
    color: #2B2B2B;

    h2 {
      margin-bottom: 0;
      align-self: flex-start;
    }

    &>div {
      span {
        font-size: 20px;
        font-family: $Sriracha;
      }
    }
  }
}

#success {
  background-color: map-get($colors, white-50 );
  .container {
    display: flex;
    justify-content: center;
    .box-success {
      background: #FEFEFE;
      box-shadow: 0 3px 6px #82828246;
      width: 680px;
      padding: 50px 0 40px;
      text-align: center;

      &>.title {
        color: #8DC640;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        img {
          width: 38px;
          margin-left: 3px;
        }
      }
      p {
        max-width:350px;
        margin: 0 auto 25px;
        padding: 0 10px;
      }
      .bg-menu {
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0 10px;
        .title {
          color: map-get($colors, white-50 );
        }
      }
      a {
        background-color: #C49F5C;
        width: 230px;
        height: 50px;
        margin: 30px auto 0;
      }
    }
  }
}

#quem-somos {
  color: #2B2B2B;
  margin-top: 11rem;
  padding: 90px 0;
  background-position: center 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: map-get($colors, white-50 );

  .row {
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > div {
        margin: 0 auto;
        background: #C49F5C;
        max-width: 405px;
        margin: -20px 0 0 -20px;
        video {
          transform: translate(20px, 20px);
          max-width: 100%;
        }
      }
    }
  }
  h1 {
    font-size: 2.4rem;
    margin-bottom: 10px;
  }
  a {
    font-weight: 500;
    color: #C49F5C;
    display: flex;
    align-items: center;
    // line-height: 14px;
    img {
      margin-left: 7px;
      width: 18px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  p {
    font-weight: 500;
    font-size: 1.6rem;

  }
}



// media queries
@media (max-width: 1200px) {
  .categories-navbar {
    .container {
      overflow-x: scroll;
    }
  }
}
@media (max-width: 992px) {
  .categories-navbar {
    .container {
      overflow-x: scroll;
      ul {
        li {
          margin-right: 20px;
        }
      }
    }
  }

  #fixed-cart-button {
    right: 50px;
    width: 280px;
    height:40px;
    padding: 0 30px;
    font-size: 1.4rem;
    span {
      &.price {
        font-size: 1.6rem;
      }
      align-items: center;
      img {
        width: 22px;
      }
    }
  }

  #fixed-whatsapp-button {
    bottom: 60px;
    img {
      width:45px;
    }
  }

  #cart {
    .address-data {
      .address {
        margin-bottom: 18px;
      }
    }
    .credit-card {
      .col-lg-6:first-child {
        margin-bottom: 18px;
      }
    }
    .resume {
      flex-direction: column;
      .title {
        align-self: center;
        margin-bottom: 10px;
      }
      &>div {
        margin-bottom: 8px;
      }
      button {
        align-self: center;
        margin-top: 5px;
      }

    }
  }
  #success {
    .container {
      .box-success {
        .title {
          font-size: 20px;
          img {
            width: 32px;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {

  #fixed-cart-button {
    right: 10px;
    width: 250px;
    height:30px;
    padding: 0 20px;
    font-size: 1.2rem;
    span {
      &.price {
        font-size: 1.4rem;
      }
      align-items: center;
      img {
        width: 18px;
      }
    }
  }



  #cart {
    .table-wrapper {
      padding: 0 10px;
      overflow-x: scroll;
      table.table {
        // min-width: 500px;
        .product {
          width: 100%;
        }
      }
    }
    label {
      font-size: 12px;
    }
    .credit-card {
      padding: 40px 10px 20px;
    }
    .schedule {
      padding: 20px 5px;
    }
  }
}

.pristine-error {
  color: red;
}
