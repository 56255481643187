@mixin section-combos {
  .combos-top-page {
    padding: 2rem 0;
    background-color: map-get($colors, pink-400 );
  }

  .nav-pills {
    margin-top: 3rem;
    margin-bottom: 6rem;
    .nav-item {
      background-color: map-get($colors, white-50);
      border: 1px solid map-get($colors, pink-300 );
      width: 200px;
      margin-right: 1rem;
      border-radius: 5px;
      overflow: hidden;

      @media (max-width: 767.98px){
        width: 100%;
        margin-bottom: 1rem;
      }
      
      .nav-link {
        text-align: center;
        border-radius: 0;
        font-family: $Sriracha;
        padding: 1rem 0;
      }

      .nav-link.active  {
        background-color: map-get($colors, pink-300 );
      }
    }
  }

  .tab-content {
    .tab-pane {
      font-family: $Sriracha;
      color: map-get($colors, gray-50 );
    }
  }
}