@mixin swiperCombos {
  height: 300px;

  @media (max-width: 767.98px){
    height: 200px;
  }

  @media (max-width: 575.98px){
    height: 120px;  
  }

  .swiper-wrapper {
    .swiper-slide {
      width: 60%;
      border-radius: 10px;
      background-position: center;
      display: flex;
      align-items: center;

      &::after {
        content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(270deg, #fff0f000, #00000003, #00000085);
          border-radius: 10px;
        }

      p {
        font-size: 42px;
        font-family: $Sriracha;
        color: map-get($colors, white-50 );
        z-index: 3;
        margin-left: 2rem;

        @media (max-width: 767.98px){
          font-size: 28px;

        }
      }
    }
  }
}