@mixin footer {
  .wrapper {
    padding: 4rem 0 0;
    border-top: 2px solid #fff;
    background-color: map-get($colors, pink-baby);

    .img-fluid {
      width: 90%;

      @media (max-width: 575.98px) {
        width: 50%;
      }
    }

    a.link,
    p.title {
      font-family: $Sriracha;
      font-size: 18px;
      color: map-get($colors, pink-50);
      text-decoration: none;
    }

    a.icon {
      color: map-get($colors, wood-50);
      font-size: 24px;
    }

    .copyright {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ffdada;

      @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
      }

      p {
        color: map-get($colors, wood-50);
        margin-bottom: 0px;
      }
    }
  }
}
