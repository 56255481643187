@mixin quemSomos-wrapper {
  background-color: map-get($colors, pink-baby );

  .img-equipe {
    height: 300px;
    background-position: inherit;
    border: 3px solid map-get($colors, pink-300 );
    @media (max-width: 575.98px){
      height: 220px;
    }
  }
}