@mixin breadcramb {
  padding: 20px 0;
  background-color: map-get($colors, pink-baby );
  .content {
    display: flex;
    color: map-get($colors, pink-50 );
    font-size: 16px;

    p {
      color: map-get($colors, pink-50 );
      margin-bottom: 0;
    }
  }
}
