@mixin section-clientes {
  padding: 8rem 0;
  @media (max-width: 575.98px){
    padding: 4rem 0;
  }

  .clientes-content {
    @media (max-width: 991.98px){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }
  }
}