// main code
@mixin modals {
  background: rgba($color: #2A2A2A, $alpha: .42);

    .modal-dialog {
      margin: 0 auto;

      .modal-content {
        box-shadow: none;
        border: none;
        border-radius: 9px;

        .modal-body {
          padding: 2.5rem;
          color: #868584;
          img {
            // height: 160px;
            width: 100%;
            border-radius: 9px;
            object-fit: cover;
            object-position: top;
            margin-bottom: 10px;
          }
          h4 {
            color: map-get($colors, pink-50 );
            margin-bottom: 10px;
            font-size: 22px;
            font-family: $Sriracha;

          }
          p {
            margin-bottom: 15px;
          }
          h5 {
            color: map-get($colors, pink-50 );
            font-size: 1.6rem;
          }

          .form-check {
            padding-left: 1.75rem;
            display: flex;
            align-items: center;
            .form-check-input {
              margin-left: -1.75rem;
              margin-top: .1rem;
            }
          }
          input[type='radio']:before {
            content: '';
            display: block;
            position: absolute;
            border: 1px solid #868584;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
          }
          input[type='radio']:after {
            background-color: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            content: '';
            display: block;
            visibility: visible;
            transition: all .1s;
          }
          input[type='radio']:checked:after {
            background-color: map-get($colors, pink-200 );
          }
        
          .form-control[type="number"]::-webkit-inner-spin-button,
          .form-control[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          textarea {
            border-radius: 6px;
            height: 90px;
            outline: none;
            width: 100%;
            resize: none;
            padding: 5px 8px;
            &:focus {
              box-shadow: 0 0 2px 2px #c4a05c96;
            }
          }

          .add {
            @include btn-theme;
            height: 35px;
            width: 100%;
            margin-left: 20px;
            background-color: map-get($colors, pink-200 );
            justify-content: space-between;
            padding: 0 40px;
            font-family: $Sriracha;

            span:first-child {
              font-size: 1.4rem;
            }
          }
        }

      }
    }

  // media queries
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 420px;

    }
  }

  @media (max-width: 576px) {
    .modal-dialog .modal-content .modal-body .add {
      font-size: 1.1rem;
      padding: 0 10px;
    }
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    /* tablets && small laptops */
  }
}
