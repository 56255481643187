.detalhes-mural-top {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 28px;
    color: map-get($colors, white-50);
    font-family: $Sriracha;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 18px;
    color: map-get($colors, white-50);
    font-family: $Sriracha;
  }
}

#section-detalhes {
  margin-top: 4rem;
  border-bottom: 1px solid #D9D9D9;
  background-image: url(/img/bg/bg-cofee-delale.png);

  .img-product{
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
      border-radius: 30px;
    }
  }

  .detalhes-info {
    padding-bottom: 20px;
    border-bottom: 1px solid #D9D9D9;
    p {
      margin-bottom: 0px;
    }
    .info-id {
      font-weight: 400;
    }
    .info-title {
      font-size: 30px;
      color: rgb(36, 36, 36);
      font-weight: 500;
      font-family: "Sriracha", cursive;
    }

    .info-value {
      font-family: "Sriracha", cursive;
      font-size: 20px;
      color:rgb(36, 36, 36);
    }
  }

  .info-desc {
    padding: 20px 0;
    border-bottom: 1px solid #D9D9D9;
  }

  .btn-duvida {
    padding: 2rem;
    background-color: white;
    border: 1px solid map-get($colors, pink-300);
    text-decoration: none;
    width: 100%;
    text-align: center;
  }

  .btn-adicionar {
    width: 100%;
    background: linear-gradient(-45deg, #FF6D6D, #FB999A, #ff82b4, #FF6D6D);
    background-size: 800% 400%;
    padding: 2rem;
    text-decoration: none;
    display: inline-block;
    border: none;
    text-align: center;
    font-size: 17px;
    font-family: $Sriracha;
    font-weight: 300;
    color: map-get($colors, white-50 );
    transition: all .5s ease-in-out;
    animation: gradient 10s infinite cubic-bezier(.62, .28, .23, .99) both;

    &:hover {
      animation: gradient 3s infinite;
      transform: scale(1.05);
    }

    &:active {
      animation: gradient 3s infinite;
      transform: scale(0.8);
    }
  }
}
